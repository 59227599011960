import React, { Component, lazy, Suspense } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import Stomp from "stompjs";
import moment from "moment-timezone";
//
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import CssBaseline from "@material-ui/core/CssBaseline";
//
import {
    getEventTemplates,
    getCountAccessRequest,
    getCountOfPendingOvertimes,
    getCurrentUser,
    getLoginUrl,
    logout,
    registerErrorListener,
    registerLoadListener,
    unregisterErrorListener,
    unregisterLoadListener,
} from "./api/api";
//
import Loading from "./containers/loading/Loading";
import Info from "./containers/info/Info";
import { STATE } from "./containers/components/autoSuggest/UserStateAutoSuggest";
import ErrorBoundary from "./containers/components/errorBoundary/ErrorBoundary";
import ViewPlaceholder from "./containers/viewPlaceholder/ViewPlaceholder";
import CenteredView from "./containers/centeredView/CenteredView";
import SideMenu from "containers/components/shared/SideMenu";
import MobileToolbar from "mobile/components/MobileToolbar";
import ToolbarMixin from "containers/components/shared/ToolbarMixin";
import Locations from "containers/locations/Locations";
import FAQModule from "containers/FAQ/FAQModule";
import ProtectedRoute from "containers/components/router/ProtectedRoute";
import LoginPage from "containers/login/LoginPage";
import Unauthorized from "containers/login/Unauthorized";
import ThemeWrapper from "containers/components/themeWrapper/ThemeWrapper";
import { guessLocation } from "containers/components/autoSuggest/LocationAutoSuggest";
//
import "./App.scss";
import { assertUserPermissions } from "utils/routePermissions";
import ProjectReportPlus from "containers/reports/projectReportPlus/ProjectReportPlus";
import UserSettings from "containers/userSettings/UserSettings";
import TemplatesFactory from "containers/templatesManager/TemplatesFactory";
import Changelog from "./containers/components/changelog/Changelog";
//
const Timesheet = lazy(() => import("./containers/timesheet/Timesheet"));
const ProjectsReport = lazy(() => import("./containers/reports/projects/ProjectsReport"));
const AdvancedReport = lazy(() => import("./containers/reports/advanced/AdvancedReport"));
const PresenceReport = lazy(() => import("./containers/reports/presenceReport/PresenceReport"));
const Lock = lazy(() => import("./containers/lock/Lock"));
const ProjectsModule = lazy(() => import("./containers/projects/ProjectsModule"));
const Users = lazy(() => import("./containers/users/Users"));
const GroupsModule = lazy(() => import("./containers/groups/GroupsModule"));
const AccessRequests = lazy(() => import("./containers/accessRequests/AccessRequests"));
const UsersReport = lazy(() => import("./containers/reports/users/UsersReport"));
const CustomerReport = lazy(() => import("./containers/reports/customer/CustomerReport"));
const MailModule = lazy(() => import("./containers/mail/MailModule"));
const OvertimeWorklogStatusChange = lazy(() =>
    import("./containers/overtimeWorklogs/OvertimeWorklogStatusChange")
);
const TemplatesManager = lazy(() => import("./containers/templatesManager/TemplatesManager"));

export const UserContext = React.createContext();
export const NoticeContext = React.createContext();
export const TemplateContext = React.createContext();

class App extends Component {
    constructor(props) {
        super(props);

        this.onLoad = this.onLoad.bind(this);
        this.onError = this.onError.bind(this);

        this.state = {
            user: undefined,
            loading: true,
            error: null,
            drawerOpen: false,
            accessRequests: 0,
            overtimes: 0,
            template: null,
            stompClient: null,
            changeTemplate: false,
            pageReloaded: false,
        };
    }

    componentDidMount() {
        registerLoadListener(this.onLoad);
        registerErrorListener(this.onError);

        if (window.location.hash && window.location.hash.indexOf("#/error/authentication") === 0) {
            window.location.hash = "";

            this.onError({
                message: [
                    "There was some problem while validating your credentials.",
                    "Be advised that in order to login into this system for the first time, all new employees need to first login directly into Jira and wait a couple of hours to properly initialize their permissions.",
                ],
            });
        }

        const wsProtocol = window.location.protocol === "https:" ? "wss:" : "ws:";
        let wsServer = `${wsProtocol}//${window.location.host}`;

        const connectWebSocket = () => {
            if (window.location.origin === "http://localhost:3000") {
                wsServer = "ws://localhost:8080";
            }
            const socket = new WebSocket(`${wsServer}/ws-endpoint`);

            const stomp = Stomp.over(socket);
            stomp.debug = () => {};
            stomp.connect({}, () => {
                this.setState({ stompClient: stomp });
            });

            socket.onclose = () => {
                console.log("Connection with WebSocket was closed.");
                setTimeout(connectWebSocket, 10000);
            };

            return () => {
                if (this.state.stompClient) {
                    this.state.stompClient.disconnect();
                }
            };
        };

        return getCurrentUser()
            .then((json) => {
                this.setState({ user: json });
                this.setAccessRequestsNotice();
                this.setOvertimesNotice();
                this.setTemplate();
                connectWebSocket();
            })
            .catch(() => {
                this.setState({ user: null });

                if (this.state.stompClient) {
                    this.state.stompClient.disconnect();
                }
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.user !== null) {
            if (this.state.stompClient && this.state.stompClient !== prevState.stompClient) {
                this.state.stompClient.subscribe("/topic/changeTemplate", (message) => {
                    this.setState({ changeTemplate: true });
                });
                this.state.stompClient.subscribe("/topic/reload", (message) => {
                    this.setState({ pageReloaded: true });
                });
            }
            if (this.state.changeTemplate) {
                this.setTemplate();
            }
            if (this.state.pageReloaded) {
                window.location.reload();
                this.setState({ pageReloaded: false });
            }
        }
    }

    componentWillUnmount() {
        unregisterErrorListener(this.onError);
        unregisterLoadListener(this.onLoad);
    }

    onLoad(isLoading) {
        if (isLoading !== this.state.loading) {
            this.setState({ loading: isLoading });
        }
    }

    onError(error, background) {
        if (error && error.code === 401) {
            return this.setState({ user: null });
        }

        if (!background) {
            return this.setState({ error });
        }
    }

    onLogout() {
        logout()
            .catch(console.error)
            .then(() => {
                this.setState({ user: null, drawerOpen: false, accessRequests: 0, overtimes: 0 });
                this.props.history.replace("/");
            });
    }

    onLogin() {
        window.location.href = getLoginUrl();
        this.setState({ drawerOpen: false });
    }

    handleDrawerToggle() {
        this.setState({ drawerOpen: !this.state.drawerOpen });
    }

    setOvertimesNotice() {
        getCountOfPendingOvertimes().then((response) => this.setState({ overtimes: response }));
    }

    setAccessRequestsNotice() {
        getCountAccessRequest().then((response) => this.setState({ accessRequests: response }));
    }

    setTemplate() {
        const today = Date.now();
        guessLocation("", this.state.user).then((loc) => {
            getEventTemplates().then((data) => {
                this.setState({
                    template: data.find(
                        (el) =>
                            moment(today) >= moment(el.startDate) &&
                            moment(today) <= moment(el.endDate) &&
                            el.active === true &&
                            el.locationId == loc.id
                    ),
                    changeTemplate: false,
                });
            });
        });
    }

    sendTemplateChange = () => {
        this.state.stompClient.send("/app/changeTemplate", {}, "changeTemplate");
    };

    sendReloadChange = () => {
        this.state.stompClient.send("/app/reloadPage", {}, "reload");
    };

    render() {
        const { loading, user } = this.state;

        return (
            <ThemeWrapper>
                <CssBaseline />
                <UserContext.Provider value={this.state.user}>
                    <Modal
                        open={loading}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 192,
                        }}>
                        <Fade in={loading}>
                            <Loading />
                        </Fade>
                    </Modal>

                    <Info
                        open={!!this.state.error}
                        type="Error"
                        message={this.state.error && this.state.error.message}
                        onClose={() => this.setState({ error: null })}
                    />

                    {this.renderContent(user)}
                </UserContext.Provider>
            </ThemeWrapper>
        );
    }

    renderContent(user) {
        return (
            <React.Fragment>
                <NoticeContext.Provider
                    value={{
                        overtimes: this.state.overtimes,
                        accessRequests: this.state.accessRequests,
                        setAccessRequestsNotice: () => this.setAccessRequestsNotice(),
                        setOvertimesNotice: () => this.setOvertimesNotice(),
                    }}>
                    <div id="browser-view">
                        {!!user && (
                            <>
                                <MobileToolbar toggleDrawer={this.handleDrawerToggle.bind(this)} />

                                <SideMenu
                                    user={user}
                                    onLogoutHandler={this.onLogout.bind(this)}
                                    onLoginHandler={this.onLogin.bind(this)}
                                    mobileOpen={this.state.drawerOpen}
                                    handleDrawerToggle={this.handleDrawerToggle.bind(this)}
                                />
                            </>
                        )}
                        <div id="contentWrapper">
                            <ToolbarMixin />

                            <TemplateContext.Provider
                                value={{
                                    template: this.state.template,
                                    setUseTemplate: () => this.setTemplate(),
                                    sendTemplateChange: () => this.sendTemplateChange(),
                                    sendReloadChange: () => this.sendReloadChange(),
                                }}>
                                {!!user && !!this.state.template && (
                                    <TemplatesFactory
                                        user={user}
                                        template={this.state.template}></TemplatesFactory>
                                )}
                                <div id="content">{this.createRoutes()}</div>
                            </TemplateContext.Provider>
                        </div>
                    </div>
                </NoticeContext.Provider>
            </React.Fragment>
        );
    }

    changeNumberOfPieces(count) {
        this.setState({ numberOfPieces: this.state.numberOfPieces + count });
        console.log(this.state.numberOfPieces);
    }
    createRoutes() {
        const { user } = this.state;

        if (user && user.State === STATE.INACTIVE) {
            return <CenteredView>Your account is inactive.</CenteredView>;
        }

        if (!user) {
            return (
                <Suspense fallback={<ViewPlaceholder />}>
                    <LoginPage onLogin={this.onLogin.bind(this)} />
                </Suspense>
            );
        }

        return (
            <Switch>
                <Route path="/login">
                    <ErrorBoundary message={"Could not fetch login view."}>
                        <Suspense fallback={<ViewPlaceholder />}>
                            <LoginPage onLogin={this.onLogin.bind(this)} />
                        </Suspense>
                    </ErrorBoundary>
                </Route>

                <Route path="/unauthorized">
                    <ErrorBoundary message={"Could not fetch unauthorized view."}>
                        <Suspense fallback={<ViewPlaceholder />}>
                            <Unauthorized />
                        </Suspense>
                    </ErrorBoundary>
                </Route>

                <Route path="/users">
                    <ErrorBoundary message={"Could not fetch users view."}>
                        <ProtectedRoute
                            isAllowed={assertUserPermissions("/users", user?.Permissions)}>
                            <Suspense fallback={<ViewPlaceholder />}>
                                <Users />
                            </Suspense>
                        </ProtectedRoute>
                    </ErrorBoundary>
                </Route>

                <Route path="/groups">
                    <ErrorBoundary message={"Could not fetch groups view."}>
                        <ProtectedRoute
                            isAllowed={assertUserPermissions("/groups", user?.Permissions)}>
                            <Suspense fallback={<ViewPlaceholder />}>
                                <GroupsModule />
                            </Suspense>
                        </ProtectedRoute>
                    </ErrorBoundary>
                </Route>

                <Route path="/access-requests">
                    <ProtectedRoute
                        isAllowed={assertUserPermissions("/access-requests", user?.Permissions)}>
                        <ErrorBoundary message={"Could not fetch access requests view."}>
                            <Suspense fallback={<ViewPlaceholder />}>
                                <AccessRequests />
                            </Suspense>
                        </ErrorBoundary>
                    </ProtectedRoute>
                </Route>

                <Route path="/overtime">
                    <ProtectedRoute isAllowed={false}>
                        <ErrorBoundary message={"Could not fetch overtime request view."}>
                            <Suspense fallback={<ViewPlaceholder />}>
                                <OvertimeWorklogStatusChange />
                            </Suspense>
                        </ErrorBoundary>
                    </ProtectedRoute>
                </Route>

                <Route path="/projects">
                    <ProtectedRoute
                        isAllowed={assertUserPermissions("/projects", user?.Permissions)}>
                        <ErrorBoundary message={"Could not fetch projects view."}>
                            <Suspense fallback={<ViewPlaceholder />}>
                                <ProjectsModule />
                            </Suspense>
                        </ErrorBoundary>
                    </ProtectedRoute>
                </Route>

                <Route path="/reports/advanced">
                    <ProtectedRoute
                        isAllowed={assertUserPermissions("/reports/advanced", user?.Permissions)}>
                        <ErrorBoundary message={"Could not fetch advanced reports view."}>
                            <Suspense fallback={<ViewPlaceholder />}>
                                <AdvancedReport />
                            </Suspense>
                        </ErrorBoundary>
                    </ProtectedRoute>
                </Route>
                <Route path="/reports/presence">
                    <ProtectedRoute
                        isAllowed={assertUserPermissions("/reports/presence", user?.Permissions)}>
                        <ErrorBoundary message={"Could not fetch presence report view."}>
                            <Suspense fallback={<ViewPlaceholder />}>
                                <PresenceReport />
                            </Suspense>
                        </ErrorBoundary>
                    </ProtectedRoute>
                </Route>
                <Route path="/reports/projects">
                    <ProtectedRoute
                        isAllowed={assertUserPermissions("/reports/projects", user?.Permissions)}>
                        <ErrorBoundary message={"Could not fetch project report view."}>
                            <Suspense fallback={<ViewPlaceholder />}>
                                <ProjectsReport />
                            </Suspense>
                        </ErrorBoundary>
                    </ProtectedRoute>
                </Route>
                <Route path="/reports/project-plus">
                    <ProtectedRoute
                        isAllowed={assertUserPermissions(
                            "/reports/project-plus",
                            user?.Permissions
                        )}>
                        <ErrorBoundary message={"Could not fetch project report plus view."}>
                            <Suspense fallback={<ViewPlaceholder />}>
                                <ProjectReportPlus />
                            </Suspense>
                        </ErrorBoundary>
                    </ProtectedRoute>
                </Route>

                <Route path="/reports/users">
                    <ProtectedRoute
                        isAllowed={assertUserPermissions("/reports/users", user?.Permissions)}>
                        <ErrorBoundary message={"Could not fetch users report view."}>
                            <Suspense fallback={<ViewPlaceholder />}>
                                <UsersReport />
                            </Suspense>
                        </ErrorBoundary>
                    </ProtectedRoute>
                </Route>

                <Route path="/reports/customer">
                    <ProtectedRoute
                        isAllowed={assertUserPermissions("/reports/customer", user?.Permissions)}>
                        <ErrorBoundary message={"Could not fetch customer report view."}>
                            <UserContext.Consumer>
                                {(contextUser) => (
                                    <Suspense fallback={<ViewPlaceholder />}>
                                        <CustomerReport user={contextUser} />
                                    </Suspense>
                                )}
                            </UserContext.Consumer>
                        </ErrorBoundary>
                    </ProtectedRoute>
                </Route>

                <Route path="/lock">
                    <ProtectedRoute isAllowed={assertUserPermissions("/lock", user?.Permissions)}>
                        <ErrorBoundary message={"Could not fetch locks view."}>
                            <Suspense fallback={<ViewPlaceholder />}>
                                <Lock />
                            </Suspense>
                        </ErrorBoundary>
                    </ProtectedRoute>
                </Route>

                <Route path="/mail">
                    <ProtectedRoute
                        isAllowed={assertUserPermissions("MailModule", user?.Permissions)}>
                        <ErrorBoundary message={"Could not fetch mail view."}>
                            <Suspense fallback={<ViewPlaceholder />}>
                                <MailModule />
                            </Suspense>
                        </ErrorBoundary>
                    </ProtectedRoute>
                </Route>

                <Route path="/locations">
                    <ProtectedRoute
                        isAllowed={assertUserPermissions("/locations", user?.Permissions)}>
                        <ErrorBoundary message={"Could not fetch locations view."}>
                            <Suspense fallback={<ViewPlaceholder />}>
                                <Locations />
                            </Suspense>
                        </ErrorBoundary>
                    </ProtectedRoute>
                </Route>

                <Route path="/templatesManager">
                    <ProtectedRoute
                        isAllowed={assertUserPermissions("/templatesManager", user?.Permissions)}>
                        <ErrorBoundary message={"Could not fetch locations view."}>
                            <Suspense fallback={<ViewPlaceholder />}>
                                <TemplatesManager />
                            </Suspense>
                        </ErrorBoundary>
                    </ProtectedRoute>
                </Route>

                <Route path="/help">
                    <ProtectedRoute isAllowed={user}>
                        <ErrorBoundary message={"Could not fetch FAQ view."}>
                            <Suspense fallback={<ViewPlaceholder />}>
                                <FAQModule />
                            </Suspense>
                        </ErrorBoundary>
                    </ProtectedRoute>
                </Route>

                <Route path="/settings">
                    <ProtectedRoute isAllowed={user}>
                        <ErrorBoundary message={"Could not fetch settings view."}>
                            <Suspense fallback={<ViewPlaceholder />}>
                                <UserSettings user={user} />
                            </Suspense>
                        </ErrorBoundary>
                    </ProtectedRoute>
                </Route>

                <Route path="/changelog">
                    <ProtectedRoute isAllowed={user}>
                        <ErrorBoundary message={"Could not fetch changelog view."}>
                            <Suspense fallback={<ViewPlaceholder />}>
                                <Changelog />
                            </Suspense>
                        </ErrorBoundary>
                    </ProtectedRoute>
                </Route>

                <Route path="/">
                    <ErrorBoundary message="Could not fetch timesheet view.">
                        <ProtectedRoute isAllowed={user}>
                            <UserContext.Consumer>
                                {(contextUser) => (
                                    <Suspense fallback={<ViewPlaceholder />}>
                                        <Timesheet user={contextUser} />
                                    </Suspense>
                                )}
                            </UserContext.Consumer>
                        </ProtectedRoute>
                    </ErrorBoundary>
                </Route>
            </Switch>
        );
    }
}

export default withRouter(App);
