import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { UserContext } from "App";
import { loginExternal } from "api/api";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import CenteredView from "../centeredView/CenteredView";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import Divider from "@material-ui/core/Divider";

import TTMSLogo from "../components/logo/TTMSLogo";
import Typography from "@material-ui/core/Typography";

import { ReactComponent as windowsLogo } from "../../assets/logo/WinLogo.svg";

import SvgIcon from "@material-ui/core/SvgIcon";
import ThemeSwitchButton from "containers/components/themeSwitchButton/ThemeSwitchButton";
import Version from "containers/components/misc/Version";

const schema = yup
    .object({
        email: yup.string().required(),
        password: yup.string().required(),
    })
    .required();

const useStyles = makeStyles((theme) => ({
    version: {
        position: "absolute",
        right: "30px",
        bottom: "20px",
    },
}));

function LoginPage({ onLogin }) {
    const userContext = useContext(UserContext);
    const classes = useStyles();
    const [showPasswordToggle, setShowPasswordToggle] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: {
            mail: "",
            password: "",
        },
        resolver: yupResolver(schema),
        mode: "onBlur",
    });

    const handleShowPasswordToggle = () => {
        setShowPasswordToggle(!showPasswordToggle);
    };

    const onSubmit = (data) => {
        loginExternal(data.email, data.password);
    };

    return (
        <>
            {userContext ? (
                <Redirect to="/" />
            ) : (
                <Grid container justifyContent={"center"} spacing={2}>
                    <Grid item container justifyContent={"flex-start"} spacing={1} xs={12}>
                        <ThemeSwitchButton />
                    </Grid>
                    <Grid item xs={8} sm={6} md={3}>
                        <Grid container justifyContent={"center"} spacing={4}>
                            <Grid item>
                                <Divider />

                                <div className="brand-container">
                                    <TTMSLogo className="brand-logo" />
                                    <Typography className="brand-title">TimeSheets</Typography>
                                </div>

                                <Divider />
                            </Grid>
                            <Grid item>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container justifyContent={"center"} spacing={4}>
                                        <Grid item xs={12}>
                                            <TextField
                                                {...register("email")}
                                                id="email"
                                                name="email"
                                                type="email"
                                                label="Email"
                                                fullWidth={true}
                                                error={!!errors?.email}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel>Password</InputLabel>
                                                <Input
                                                    {...register("password")}
                                                    id="password"
                                                    type={showPasswordToggle ? "text" : "password"}
                                                    fullWidth={true}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleShowPasswordToggle}>
                                                                {showPasswordToggle ? (
                                                                    <Visibility />
                                                                ) : (
                                                                    <VisibilityOff />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                disabled={isSubmitting}>
                                                Login
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                            <Grid item xs={12}>
                                <CenteredView>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={onLogin}
                                        startIcon={
                                            <SvgIcon
                                                component={windowsLogo}
                                                viewBox="0 0 800 600"
                                            />
                                        }>
                                        TTMS Azure
                                    </Button>
                                </CenteredView>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent={"flex-end"}
                        spacing={4}
                        xs={12}
                        className={classes.version}>
                        <Version />
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default LoginPage;
